#main-slider-container{
    width: 90%;
    height:fit-content;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;


 

}

#slider{
    width: 100%;
    height: 320px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;

}

#slider::-webkit-scrollbar{
    display: none;
}

.slider-icon.left, .slider-icon.right{
    float: left;
    font-size: 40px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    opacity: 0.5;
    box-shadow: 2px 2px 2px 2px rgb(24, 22, 22);
    cursor: pointer;


}

.slider-icon.left{
    left: 0;
}

.slider-icon.right{
    right: 0;
}

.slider-icon.left:hover, .slider-icon.right:hover{
    opacity: 1;

}

.slider-card{
    width: 22%;
    height: 300px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin-left: 1.5%;
    margin-right: 1.5%;
    box-shadow: 2px 2px 2px 2px rgb(24, 22, 22);
    cursor: pointer;
}

.slider-card-image{
    width: 100%;
    height: 220px;
    background-color: #80808026;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

.slider-card-title, .slider-card-description{
    margin: 5px 0px 5px 10px;
}

.slider-card-title{
    font-weight: 500;
    margin-top: 10px;
}

.slider-card-description{
    opacity: 0.5;
    font-size: 10px;
}

@media only screen and (max-width: 768px) {
    .slider-card{
        width: 90%;
        height: 300px;
       
    }


  }