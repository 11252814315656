.worksdiv{
    padding-top: 70px;
    width: 100%;
    /* height: 80vh; */
    border-top: 1px solid #364;
    justify-content: center;
    margin-bottom: 100px;
}

#topicdiv{
    color: white;
    font-size: 50px;
    height: fit-content;
    margin-bottom: 70px;
}

