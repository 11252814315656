.homediv{
    background-color: #24262B;
    height: auto;
    padding-top: 70px;
    padding-bottom: 30px;
}

#nameid{
    color: white;
    font-size: 10vw;
    justify-content: center;
    padding-top: 50px;
}

/*Text animation css*/

.textanimation{
    display: flex;
    background-color: #24262B;
    padding-top: 10px;
    color: white;
    justify-content: center;
    height: 40px;
}

.wrapper{
    display: flex;
  }

  .wrapper .static-txt{
    color: #fff;
    font-size: 60px;
    font-weight: 400;
  }

  .wrapper .dynamic-txts{
    margin-left: 15px;
    height: 90px;
    line-height: 90px;
    overflow: hidden;
  }

  .dynamic-txts li{
    list-style: none;
    color: #FC6D6D;
    font-size: 60px;
    font-weight: 500;
    position: relative;
    top: 0;
    animation: slide 12s steps(4) infinite;
    text-align: left;
  }
  @keyframes slide {
    100%{
      top: -360px;
    }
  }

  .dynamic-txts li span{
    position: relative;
    margin: 5px 0;
    line-height: 90px;
    
  }

  .dynamic-txts li span::after{
    content: "";
    position: absolute;
    left: 0;
    height: 110%;
    width: 100%;
    background: #24262B;
    border-left: 2px solid #FC6D6D;
    animation: typing 3s steps(10) infinite;
  }
  @keyframes typing {
    40%, 60%{
      left: calc(100% + 30px);
    }
    100%{
      left: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .dynamic-txts li {
        font-size: 20px; /* Adjust font size for smaller screens */
        font-weight: 100; /* Adjust font weight if necessary */
        text-align: center; /* Center-align text for better readability on smaller screens */
    }
    .dynamic-txts li span{
      position: relative;
      margin: 5px 0;
      line-height: 90px;
      top: -30px;
    }
  
    .dynamic-txts li span::after{
      content: "";
      position: absolute;
      left: 0;
      height: 30px;
      width: 100%;
      background: #24262B;
      border-left: 2px solid #FC6D6D;
      animation: typing 3s steps(10) infinite;
      margin-top: 30px;
    }

}

@media only screen and (max-width: 768px) {
  .static-txt p{
    display: absolute;
    font-size: 20px;
    /* background-color: #FC6D6D; */
    font-weight: 100;
    margin-top: 0;
  }
}

  /*down arrow animation*/

  .arrow{
    margin-top: 70px;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-1%);
  }

  .arrow span{
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid #FFC800;
    border-right: 5px solid #FFC800;
    margin: 5px;
    transform: rotate(45deg);
    animation: animate 2s infinite;

  }

  .arrow span:nth-child(2){
    animation-delay: -0.2s;


  }

  .arrow span:nth-child(3){
    animation-delay: -0.4s;
  }

  .links{
    /* background-color: rgb(245, 245, 245); */
    width: 100%;
    margin-top: 300px;
    /* color: white; */
    font-size: xx-large;
    justify-content: space-around;
    display: flex;
    /* background-color: white; */
    border-radius: 10px;

  top: 0;
  }

  .links a{
    color: #ff7b00;
  }
  .profiles{
    color: white;
    text-decoration: none;
  }

  @keyframes animate {
    0%{
      opacity: 0;
      transform: rotate(45deg) translate(-20px,-20px);


    }

    50%{
      opacity: 1;
    }

    100%{
      opacity: 0;
      transform: rotate(45deg) translate(30px,30px);
    }
    
  }


  