.experience-container{
    padding-top: 70px;
    box-sizing: border-box;
    /* background-image: linear-gradient(135deg,#fff 0%, #fff 100%); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;min-height: 100vh;
    margin: 0;
    background: linear-gradient(270deg, #ffb2ea, #ffffff);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 52s ease infinite;
    -moz-animation: AnimationName 52s ease infinite;
    animation: AnimationName 52s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.experiencetopic{
    color: #000;
    font-size: 65px;
  }
  

.timeline-container{
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
    color: #000;
    width: 80%;


}

.timeline-container::after{
    background-color: #FF4500;
    content: '';
    position: absolute;
    left: calc(50%);
    width: 4px;
    height: 100%;
}

.timeline-item{
    display:flex;
    justify-content: flex-end;
    padding-right: 30px;
    margin: 10PX 0;
    width: 50%;


}

.timeline-item:nth-child(odd){
    align-self: flex-end;
    justify-content: flex-start;
    padding-right: 0px;
    padding-left: 30px;
}

.timeline-item-content{
    max-width: 70%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow:  0 0 5px rgba(0,0,0,0.3);
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    padding: 15px;
    text-align: right;

}


.timeline-item-content::after{
    content: '';
    background-color: white;
    width: 15px;
    position: absolute;
    height: 15px;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-item:nth-child(odd) .timeline-item-content{
    text-align: left;
    align-items: flex-start;
}

.timeline-item:nth-child(odd) .timeline-item-content::after{
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    right: auto;
    left: -7.5px;
}

.timeline-item-content .tag{
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.timeline-item:nth-child(odd) .timeline-item-content .tag{
    left: auto;
    right: 5px;
}

.timeline-item-content time{
    color: #333;
    font-size: 12px;
}

.timeline-item-content p{
    font-size: 16px;
    line-height: 24px;
    margin: 15px;
    max-width: 250px;
}

.timeline-item-content a{
    color: #333;
    text-decoration: none;
    font-size: 14px;
}

.timeline-item-content a::after{
    content: '➤';
    font-size: 12px;
}

.timeline-item-content .circle{
    background-color: #fff;
    border: 3px solid #FF4500;
    width: 20px;
    border-radius: 50%;
    top: calc(50% - 10px);
    right: -41px;
    height: 20px;
    position: absolute;
    z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle{
    right: auto;
    left: -39px;
}

@media screen and (max-width:767px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content{
        padding: 15px 10px;
        text-align: center;
        align-items: center;

    }

    .timeline-item-content .tag{
        width: calc(100% - 10px);
        font-size: 8px;
    }

    .timeline-item-content time{
        margin-top: 30px;
        font-size: 8px;
    }

    .timeline-item-content a{
        text-decoration: underline;
        font-size: 8px;
    }

    .timeline-item-content a::after{
        display: none;
    }

    .timeline-item-content p{
        font-size: 10px;
        line-height: 18px;
        margin: 2px;
        max-width: 250px;
    }



    
    
}
/*here ended*/