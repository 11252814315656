.nav-link {
    color: white !important; /* Set the color of the links to white */
  }

  .navbar-toggler{
    /* background-color: rgba(255, 255, 255, 0.169) !important; */
    /* border-color: rgb(139, 138, 138) !important; */
  }

  .navbar-toggler .navbar-toggler-icon{
    background-color: rgb(154, 151, 151);
    border-radius: 2px;
  }

  @media only screen and (min-width: 768px) {
    .navbar-brand{
     padding-right: 60%;
    }
  }

