.aboutdiv{
    /* background-color: 24262B; */
    padding-top: 70px;
    height: auto;
   /* border-top: 1px solid white ;*/
    /* color: white; */
    background-color: white;

}

.mydetails{
    display: flex;
    width: 100%;
    /* margin-bottom: 100px; */
}



/* .myimage:hover{
    transform: rotate(2deg);
} */



.detailsul{
    padding-left: 0 !important;
}

.myimagediv{
    width: 40%;
}

.detailsdiv{
    padding: 40px;
    text-align: left;
    align-content: center;
    width: 50%;
}

.myimage{
    padding: 40px;
    height: auto;
    width: 100%;
    /* border-radius: 50%; */
    /* transition: 0.2s infinite; */
}

.detailsli{
    list-style-type: none;
    padding-left: 0px;
    margin-top: 30px;
    display: flex;
}

.abouttextdiv{
    width: fit-content;
    font-size: 55px;
    border-bottom: 3px solid yellow;
    margin-bottom: 10px;
    display: flexbox;
}


.introdiv{
    color: #2E8BC0;
    font-size: 35px;
}

.topic{
    display: inline-block;
    /* margin-right: 2px; */
    width: 120px;

}

.description{
    width: fit-content;
    color: #2E8BC0;
    text-align: left;

}

.aboutdiv h6{
    line-height: 1.5;
}




@media  screen and (max-width:1000px) {
    .mydetails{
        flex-direction: column;
    }
    
}


@media screen and (max-width:1000px) {
    .myimagediv{
        margin-top: 0px;
        margin-bottom: 60px;
        text-align:start;

    }
    .aboutdiv{
        height: auto;
    }
    .aboutdiv h6{
        text-align: justify;
    }
    
}

.responsivediv{
    width: fit-content;
}
@media screen and(max-width:514px) {
    .responsivediv{
        float: right;
    }
    
}


@media only screen and (max-width: 768px) {
    .topic{
        display: flex;
        width: auto;
        padding-right: 10%;
    }
    .detailsli{
        list-style-type: none;
        padding-left: 0px;
        margin-top: 30px;
        display: flex;
    }


  }

  .logos{
    /* background-color: rgb(199, 14, 14); */
    overflow: hidden;
    white-space: nowrap;
    

  }

  .logo-slide img{
    width: 50px;
    height: auto;
    margin: 80px ;
  }

  .logo-slide{
    display: inline-block;
    /* padding-left: 100px; */
    animation: 10s scroll infinite linear;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      /* Translate by the width of the container */
      transform: translateX(-100%);
    }
  }

